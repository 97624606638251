// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {handleActions} from 'redux-actions';

import {StreamingState} from '@kepler.gl/schemas';
import {ActionTypes} from '@kepler.gl/actions';
import {DEFAULT_NB_TURTLES} from '@kepler.gl/constants';

import * as streamingReducers from './streaming-reducers';

export const INITIAL_STREAMING_STATE: StreamingState = {
  appType: 'turtle',
  datasetGroups: new Map(),
  loadingDatasets: new Set(),
  streamedDatas: new Map(),
  nbTurtles: DEFAULT_NB_TURTLES
};

const actionHandler: {[key: string]: (arg0: StreamingState, arg1: any) => StreamingState} = {
  [ActionTypes.CHANGE_APP_TYPE]: streamingReducers.changeAppTypeUpdater,
  [ActionTypes.SET_TURTLE_NUMBER]: streamingReducers.setTurtleNumberUpdater,
};

export const streamingStateReducerFactory = (initialState: Partial<StreamingState> = {}) => {
  const mergedState = {
    ...INITIAL_STREAMING_STATE,
    ...initialState,
    initialState
  };
  const handler = handleActions(actionHandler, mergedState);
  return handler;
};

// export default streamingStateReducerFactory();
