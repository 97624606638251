// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {ArrowTable} from '@loaders.gl/schema';

import Task from 'react-palm/tasks';
import {createAction} from 'redux-actions';
import {Interval} from 'luxon';

import {StreamingAppType} from '@kepler.gl/schemas';

import {ActionTypes} from './action-types';
import {TurtleConfig, AdditionalConfig} from '../../types/index';

export type ChangeAppTypePayload = {
  appType: StreamingAppType;
};
export const changeAppType = createAction<ChangeAppTypePayload>(ActionTypes.CHANGE_APP_TYPE);

export type CheckDownloadDatasetsPayload = {
  // Empty type
};

export type LoadedTurtleDataEntry = {
  datasetId: string;
  table: ArrowTable;
  chunkTime: Interval;
  nbTurtle: number;
};

export type LoadedAdditionalDataEntry = {
  config: AdditionalConfig;
  json: any;
};

export type LoadedPlasticDataEntry = {
  table: ArrowTable;
  chunkTime: Interval;
};

export type LoadedDataEntry =
  | ({
      type: 'turtle-data';
    } & LoadedTurtleDataEntry)
  | ({
      type: 'additional-data';
    } & LoadedAdditionalDataEntry)
  | ({
      type: 'plastic-data';
    } & LoadedPlasticDataEntry);

export type LoadingDataEndedPayload = {
  data: LoadedDataEntry[];
};

export type LoadingTurtleConfigsEndedPayload = {
  turtleConfigs: TurtleConfig[];
};

export type LoadingAdditionalConfigsEndedPayload = {
  additionalConfigs: AdditionalConfig[];
};

export type LoadingNestEndedPayload = {
  data: any;
};

export const checkDownloadDatasets = createAction<CheckDownloadDatasetsPayload>(
  ActionTypes.CHECK_DOWNLOAD_DATASETS
);
export const loadingDataEnded = createAction<LoadingDataEndedPayload>(
  ActionTypes.LOADING_DATA_ENDED
);
export const loadingTurtleConfigsEnded = createAction<LoadingTurtleConfigsEndedPayload>(
  ActionTypes.LOADING_TURTLE_CONFIGS_ENDED
);
export const loadingAdditionalConfigsEnded = createAction<LoadingAdditionalConfigsEndedPayload>(
  ActionTypes.LOADING_ADDITIONAL_CONFIGS_ENDED
);
export const loadingNestEnded = createAction<LoadingNestEndedPayload>(
  ActionTypes.LOADING_NEST_ENDED
);

export type SetTurtleNumberAction = {
  turtleNumber: number;
};
export const setTurtleNumber = createAction<SetTurtleNumberAction>(ActionTypes.SET_TURTLE_NUMBER);

export const checkDownloadDatasetsTask = Task.fromCallback(
  (_: {}, cb: Function) => cb(null, checkDownloadDatasets({})),
  'CHECK_DOWNLOAD_DATASETS_TASK'
);

export type UpdateDatePayload = {
  currentDate: Date;
  timerange: number;
};
export const updateDate = createAction<UpdateDatePayload>(ActionTypes.UPDATE_DATE);

export type SetTimebarDomainPayload = {
  newDomain: [Date, Date];
};
export const setTimebarDomain = createAction<SetTimebarDomainPayload>(
  ActionTypes.SET_TIMEBAR_DOMAIN
);

export const startPlayer = createAction(ActionTypes.START_PLAYER);
export const stopPlayer = createAction(ActionTypes.STOP_PLAYER);
export const playerTimerTick = createAction(ActionTypes.PLAYER_TIMER_TICK);

export type UpdateVisualDatePayload = {
  newDateMs: number;
};
export const updateVisualCurrentDate = createAction<UpdateVisualDatePayload>(
  ActionTypes.UPDATE_VISUAL_DATE
);

export type SetPlayerTimeStepPayload = {
  newTimeStep: number;
};
export const setPlayerTimeStep = createAction<SetPlayerTimeStepPayload>(
  ActionTypes.SET_PLAYER_TIME_STEP
);

export const updateRasterLayers = createAction(ActionTypes.UPDATE_RASTER_LAYER);
