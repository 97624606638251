// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {ActionTypes} from '@kepler.gl/actions';
import {KeplerGlState} from '.';
import * as combinedUpdaters from './combined-updaters';
import * as streamingUpdaters from './streaming-reducers';

/**
 * Important: Do not rename `actionHandler` or the assignment pattern of property value.
 * It is used to generate documentation
 */
const actionHandler: {[key: string]: (arg0: KeplerGlState, arg1: any) => KeplerGlState} = {
  [ActionTypes.ADD_DATA_TO_MAP]: combinedUpdaters.addDataToMapUpdater,
  [ActionTypes.MAP_STYLE_CHANGE]: combinedUpdaters.combinedMapStyleChangeUpdater,
  [ActionTypes.LAYER_TYPE_CHANGE]: combinedUpdaters.combinedLayerTypeChangeUpdater,
  [ActionTypes.LOAD_FILES_SUCCESS]: combinedUpdaters.loadFilesSuccessUpdater,
  [ActionTypes.TOGGLE_SPLIT_MAP]: combinedUpdaters.toggleSplitMapUpdater,
  [ActionTypes.REPLACE_DATA_IN_MAP]: combinedUpdaters.replaceDataInMapUpdater,
  [ActionTypes.UPDATE_RASTER_LAYER]: streamingUpdaters.updateRasterLayerUpdater,
  [ActionTypes.CHECK_DOWNLOAD_DATASETS]: streamingUpdaters.checkDownloadDatasetsUpdater,
  [ActionTypes.LOADING_TURTLE_CONFIGS_ENDED]: streamingUpdaters.loadingTurtleConfigsEndedUpdater,
  [ActionTypes.LOADING_DATA_ENDED]: streamingUpdaters.loadingDataEndedUpdater,
  [ActionTypes.LOADING_NEST_ENDED]: streamingUpdaters.loadingNestEndedUpdater,
  [ActionTypes.UPDATE_DATE]: streamingUpdaters.updateDateUpdater,
  [ActionTypes.SET_TIMEBAR_DOMAIN]: streamingUpdaters.setTimebarDomainUpdater
};

export default actionHandler;
