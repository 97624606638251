// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';

import {media} from '@kepler.gl/styles';
import {Timeline} from '@kepler.gl/types';

import TimelineSliderFactory from '../timeline-slider';
import PlaybackControlsFactory from './playback-controls';
import FloatingTimeDisplayFactory from './floating-time-display';
import {MarkerData} from '../slider/slider-markers';

const SLIDER_MARGIN_PALM = 6;

const TimeControlContainer = styled.div`
  padding: ${(props) => `${props.theme.bottomInnerPdVert}px ${props.theme.bottomInnerPdSide}px`};
  position: relative;
  margin-top: ${(props) => props.theme.bottomPanelGap}px;

  ${media.portable`
    border-top: 1px solid ${(props) => props.theme.panelBorderColor};
    border-left: 1px solid ${(props) => props.theme.panelBorderColor};
    padding: 12px 12px;
    margin-top: 0;
  `}
`;

const TimeWidgetInner = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .animation-control__time-slider {
    display: flex;
    align-items: center;
    height: 32px;
    width: 100%;
  }
  .playback-controls {
    margin-left: 16px;
  }

  ${media.palm`
    flex-direction: column;
    .playback-controls {
      margin: 0;
    }
    .animation-control__time-slider {
      width: 100%;
      position: relative;
    }
    .animation-control__time-domain {
      position: absolute;
      top: -24px;

      &.domain-start {
        left: ${SLIDER_MARGIN_PALM}px;
      }
      &.domain-end {
        right: ${SLIDER_MARGIN_PALM}px;
      }
    }
  `};
`;

const TIMELINE_PLAYBACK_STYLE = {flex: 1};

export type TimeControlProps = {
  timeline: Timeline;
  onUpdateSpeed?: (val: number) => void;
  onCommitSpeed?: (val: number) => void;
  onTogglePlay: () => void;
  onReset?: () => void;
  onSetTimelineValue: (value: [number] | [number, number]) => void;
  onCommitTimelineValue: (value: [number] | [number, number]) => void;
  className?: string;
  style?: object;
  onUpdateRange?: (range: number) => void;
  markerDatas?: MarkerData[];
  ranges:number[];
  timeStepRange: [number,number];
};

TimeControlFactory.deps = [
  PlaybackControlsFactory,
  FloatingTimeDisplayFactory,
  TimelineSliderFactory
];

function TimeControlFactory(
  PlaybackControls: ReturnType<typeof PlaybackControlsFactory>,
  FloatingTimeDisplay: ReturnType<typeof FloatingTimeDisplayFactory>,
  TimelineSlider: ReturnType<typeof TimelineSliderFactory>
) {
  const AnimationControl: React.FC<TimeControlProps> = ({
    timeline,
    className,
    style,
    onTogglePlay,
    onReset,
    onSetTimelineValue,
    onCommitTimelineValue,
    onUpdateSpeed,
    onCommitSpeed,
    onUpdateRange,
    markerDatas,
    ranges,
    timeStepRange
  }) => {
    const {
      animationWindow,
      value,
      speed,
      defaultTimeFormat,
      timeFormat,
      timezone,
      isAnimating: isPlaying
    } = timeline;

    return (
      <TimeControlContainer
        style={style}
        className={classnames('animation-control-container', className)}
      >
        <TimeWidgetInner className="animation-widget--inner">
          <TimelineSlider
            style={TIMELINE_PLAYBACK_STYLE}
            timeline={timeline}
            setTimelineValue={onSetTimelineValue}
            onCommitTimelineValue={onCommitTimelineValue}
            markerDatas={markerDatas}
          />
          <PlaybackControls
            className="animation-control-playpause"
            startAnimation={onTogglePlay}
            isPlaying={isPlaying}
            pauseAnimation={onTogglePlay}
            resetAnimation={onReset}
            speed={speed}
            updateAnimationSpeed={onUpdateSpeed}
            commitAnimationSpeed={onCommitSpeed}
            animationWindow={animationWindow}
            onRangeChanged={onUpdateRange}
            ranges={ranges}
            timeStepRange={timeStepRange}
          />
        </TimeWidgetInner>
        <FloatingTimeDisplay
          currentTime={value}
          defaultTimeFormat={defaultTimeFormat}
          timeFormat={timeFormat}
          timezone={timezone}
        />
      </TimeControlContainer>
    );
  };

  AnimationControl.defaultProps = {
    onTogglePlay: () => {},
    onUpdateSpeed: () => {},
    timeline: {
      domain: [new Date().getDate(), Date.now()],
      value: [new Date().getDate()],
      speed: 1,
      isAnimating: false
    }
  };

  return AnimationControl;
}

export default TimeControlFactory;
