// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {BrushingExtension} from '@deck.gl/extensions';
import {H3HexagonLayer} from '@deck.gl/geo-layers/typed';

import {Vector as ArrowVector} from 'apache-arrow';

import {ArrowDataContainer, findDefaultColorField, rgb2hsl, hsl2rgb} from '@kepler.gl/utils';
import {default as KeplerTable} from '@kepler.gl/table';
import {CHANNEL_SCALES, ColorRange, PROPERTY_GROUPS} from '@kepler.gl/constants';
import {
  Field,
  Merge,
  RGBColor,
  VisConfigBoolean,
  VisConfigColorRange,
  VisConfigColorSelect,
  VisConfigNumber
} from '@kepler.gl/types';

import Layer, {
  LayerBaseConfig,
  LayerBaseConfigPartial,
  LayerColorConfig,
  LayerColumn,
  LayerSizeConfig,
  LayerStrokeColorConfig
} from '../base-layer';
import LayerIcon from '../h3-hexagon-layer/h3-hexagon-layer-icon';
import {formatTextLabelData} from '../layer-text-label';

export type PlasticH3LayerColumnsConfig = {
  h3: LayerColumn;
  geometry: LayerColumn;
  density: LayerColumn;
  date: LayerColumn;
};
export const customH3RequiredColumns: ['geometry', 'h3', 'density', 'date'] = [
  'geometry',
  'h3',
  'density',
  'date'
];

export type PlasticH3LayerVisConfigSettings = {
  opacity: VisConfigNumber;
  outline: VisConfigBoolean;
  thickness: VisConfigNumber;
  strokeColor: VisConfigColorSelect;
  colorRange: VisConfigColorRange;
  strokeColorRange: VisConfigColorRange;
  individualColor: VisConfigBoolean;
};
export type PlasticH3LayerVisConfig = {
  opacity: number;
  outline: boolean;
  thickness: number;
  strokeColor: RGBColor;
  colorRange: ColorRange;
  strokeColorRange: ColorRange;
  individualColor: boolean;
};
export type PlasticH3LayerVisualChannelConfig = LayerColorConfig &
  LayerSizeConfig &
  LayerStrokeColorConfig;
export type PlasticH3LayerConfig = Merge<
  LayerBaseConfig,
  {columns: PlasticH3LayerColumnsConfig; visConfig: PlasticH3LayerVisConfig}
> &
  PlasticH3LayerVisualChannelConfig;

export type PlasticH3LayerData = {
  h3: string;
  position: number[];
  density: number;
  index: number;
};

const brushingExtension = new BrushingExtension();

export const plasticH3VisConfigs: {
  opacity: 'opacity';
  outline: 'outline';
  thickness: 'thickness';
  strokeColor: 'strokeColor';
  colorRange: 'colorRange';
  strokeColorRange: 'strokeColorRange';
  individualColor: VisConfigBoolean;
} = {
  opacity: 'opacity',
  outline: 'outline',
  thickness: 'thickness',
  strokeColor: 'strokeColor',
  colorRange: 'colorRange',
  strokeColorRange: 'strokeColorRange',
  individualColor: {
    type: 'boolean',
    label: 'color mode',
    defaultValue: false,
    property: 'individualColor',
    group: PROPERTY_GROUPS.display
  }
};

export const plasticH3PosAccessor =
  ({geometry}: PlasticH3LayerColumnsConfig) =>
  (dc: ArrowDataContainer) =>
  (d: PlasticH3LayerData) => {
    const pos = dc.valueAt(d.index, geometry.fieldIdx) as ArrowVector<any>;
    return [pos.get(0), pos.get(1)];
  };

type PlasticH3Type = 'plasticH3';
export default class PlasticH3Layer extends Layer {
  declare config: PlasticH3LayerConfig;
  declare visConfigSettings: PlasticH3LayerVisConfigSettings;

  defaultColorField: Field | undefined;

  constructor(props) {
    super(props);

    this.registerVisConfig(plasticH3VisConfigs);
    this.getPositionAccessor = (dataContainer) =>
      plasticH3PosAccessor(this.config.columns)(dataContainer);
  }

  getPositionAccessor: (dataContainer: ArrowDataContainer) => (d: PlasticH3LayerData) => any;

  static get type(): PlasticH3Type {
    return 'plasticH3';
  }

  override get name(): 'plasticH3' {
    return 'plasticH3';
  }

  override get type(): PlasticH3Type {
    return PlasticH3Layer.type;
  }

  get isAggregated(): false {
    return false;
  }

  get layerIcon() {
    return LayerIcon;
  }
  get requiredLayerColumns() {
    return customH3RequiredColumns;
  }

  get columnPairs() {
    return this.defaultPointColumnPairs;
  }

  get noneLayerDataAffectingProps() {
    return [...super.noneLayerDataAffectingProps, 'radius'];
  }

  get visualChannels() {
    return {
      color: {
        ...super.visualChannels.color,
        accessor: 'getFillColor',
        condition: (config) => config.visConfig.filled,
        defaultValue: (config) => config.color
      },
      strokeColor: {
        property: 'strokeColor',
        key: 'strokeColor',
        field: 'strokeColorField',
        scale: 'strokeColorScale',
        domain: 'strokeColorDomain',
        range: 'strokeColorRange',
        channelScaleType: CHANNEL_SCALES.color,
        accessor: 'getLineColor',
        condition: (config) => config.visConfig.outline,
        defaultValue: (config) => config.visConfig.strokeColor || config.color
      },
      size: {
        ...super.visualChannels.size,
        property: 'radius',
        range: 'radiusRange',
        fixed: 'fixedRadius',
        channelScaleType: 'radius',
        accessor: 'getRadius',
        defaultValue: 1
      }
    };
  }

  // @ts-ignore
  override updateLayerConfig(newConfig: Partial<PlasticH3LayerConfig>): this {
    const individualColor: boolean | undefined = newConfig.visConfig?.individualColor;

    if (individualColor === undefined) {
      return super.updateLayerConfig(newConfig);
    }
    if (this.defaultColorField === undefined) {
      let visConfig = newConfig.visConfig!; // `individualColor` is defined => `visConfig` is defined
      visConfig = {
        ...visConfig,
        individualColor: false
      };
      newConfig = {
        ...newConfig,
        visConfig
      };

      return super.updateLayerConfig(newConfig);
    }

    if (individualColor) {
      let visConfig = newConfig.visConfig!;
      visConfig = {
        ...this.config.visConfig,
        ...visConfig
      };
      newConfig = {
        ...newConfig,
        visConfig,
        colorField: this.defaultColorField
      };
    } else {
      let visConfig = newConfig.visConfig!;
      visConfig = {
        ...this.config.visConfig,
        ...visConfig
      };
      newConfig = {
        ...newConfig,
        visConfig,
        colorField: undefined
      };
    }

    return super.updateLayerConfig(newConfig);
  }

  setInitialLayerConfig(dataset) {
    if (!dataset.dataContainer.numRows()) {
      return this;
    }
    const defaultColorField = findDefaultColorField(dataset);

    if (defaultColorField) {
      this.updateLayerConfig({
        colorField: defaultColorField
      });
      this.updateLayerVisualChannel(dataset, 'color');
    }

    return this;
  }

  getDefaultLayerConfig(props: LayerBaseConfigPartial) {
    return {
      ...super.getDefaultLayerConfig(props),

      // add stroke color visual channel
      strokeColorField: null,
      strokeColorDomain: [0, 1],
      strokeColorScale: 'quantile'
    };
  }

  calculateDataAttribute(dataset: KeplerTable, getPosition) {
    const {filteredIndex} = dataset;

    const data: PlasticH3LayerData[] = [];
    for (let i = 0; i < filteredIndex.length; i++) {
      const index = filteredIndex[i];
      const position = dataset.getValue('geometry', index);
      const h3 = dataset.getValue('h3_04', index) as string;
      const density = Number(dataset.getValue('density', index) as bigint);

      this._maxDensity = Math.max(density, this._maxDensity);

      data.push({
        h3,
        position: [position.get(0), position.get(1)],
        density,
        index
      });
    }
    this.defaultColorField = dataset.fields.find((field) => field.name === 'turtle_idx');
    return data;
  }

  formatLayerData(datasets, oldLayerData) {
    if (this.config.dataId === null) {
      return {};
    }
    const {textLabel} = this.config;
    const {gpuFilter, dataContainer} = datasets[this.config.dataId];
    const {data, triggerChanged} = this.updateData(datasets, oldLayerData);

    // get all distinct characters in the text labels
    const textLabels = formatTextLabelData({
      textLabel,
      triggerChanged,
      oldLayerData,
      data,
      dataContainer
    });

    const getPosition = this.getPositionAccessor(dataContainer);
    const getFillColor = (d: PlasticH3LayerData) => {
      const layerColor = this.config.color;
      const value = d.density / this._maxDensity;

      let hsl = rgb2hsl(layerColor);
      hsl[2] = value;

      const rgb: RGBColor = hsl2rgb(hsl);
      return rgb;
    };
    const getFilterValue = gpuFilter.filterValueAccessor(dataContainer)();
    let accessors = this.getAttributeAccessors({dataContainer});
    accessors = {...accessors, getPosition, getFillColor, getFilterValue};

    return {
      data,
      textLabels,
      ...accessors
    };
  }
  /* eslint-enable complexity */

  updateLayerMeta(dataContainer) {
    const getPosition = this.getPositionAccessor(dataContainer);
    const bounds = this.getPointsBounds(dataContainer, getPosition);
    this.updateMeta({bounds});
  }

  renderLayer(opts) {
    const {data, gpuFilter, interactionConfig} = opts;

    const layerProps = {
      stroked: this.config.visConfig.outline,
      lineWidthScale: this.config.visConfig.thickness
    };

    const updateTriggers = {
      getPosition: this.config.columns,
      getFilterValue: gpuFilter.filterValueUpdateTriggers,
      ...this.getVisualChannelUpdateTriggers()
    };

    const defaultLayerProps = this.getDefaultDeckLayerProps(opts);
    const brushingProps = this.getBrushingExtensionProps(interactionConfig);
    const extensions = [...defaultLayerProps.extensions, brushingExtension];

    const trajectoryLayer = new H3HexagonLayer<PlasticH3LayerData>({
      ...defaultLayerProps,
      ...brushingProps,
      ...layerProps,
      ...data,
      id: `${defaultLayerProps.id}-traj`,
      parameters: {
        // no altitude
        depthTest: false
      },
      lineWidthUnits: 'meters',
      updateTriggers,
      extensions,
      transitions: {
        getPosition: {
          type: 'interpolation'
        }
      },

      getHexagon: (d) => d.h3
    });

    return [trajectoryLayer];
  }

  private _maxDensity = 0;
}
