// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {rgb as d3Rgb} from 'd3-color';
import {ColorRange} from '@kepler.gl/constants';
import {HexColor, RGBColor} from '@kepler.gl/types';

/**
 * get r g b from hex code
 *
 * @param hex
 * @returns array of r g bs
 */
export function hexToRgb(hex: string): RGBColor {
  const result = isHexColor(hex);

  if (!result) {
    return [0, 0, 0];
  }

  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  return [r, g, b];
}

/**
 * Converts from RGB color space to HSL.
 *
 * @param [r, g, b] should be between 0 and 255
 * @returns [h, s, l] respectively hue, saturation and luminance
 */
export function rgb2hsl([r, g, b]: [number, number, number]): [number, number, number] {
  r /= 255;
  g /= 255;
  b /= 255;

  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);
  const d = max - min;
  let hp: number;
  if (d === 0) hp = 0;
  else if (max === r) hp = ((((g - b) / d) % 6) + 6) % 6;
  else if (max === g) hp = (b - r) / d + 2;
  else if (max === b) hp = (r - g) / d + 4;
  else throw new Error('Unreachable code');
  const h = hp * 60;

  const l = (min + max) / 2;
  const s = d / (1 - Math.abs(2 * l - 1));

  return [h, s, l];
}

/**
 * Converts from the HSL color space to RGB.
 *
 * @param h [0:360]°
 * @param s [0:1]
 * @param l [0:1]
 * @returns [r, g, b] between 0 and 255
 */
export function hsl2rgb([h, s, l]: [number, number, number]): [number, number, number] {
  const c = (1 - Math.abs(2 * l - 1)) * s;
  const hp = h / 60;
  const x = c * (1 - Math.abs((hp % 2) - 1));

  let rgb1: [number, number, number];
  if (isNaN(h)) rgb1 = [0, 0, 0];
  else if (hp <= 1) rgb1 = [c, x, 0];
  else if (hp <= 2) rgb1 = [x, c, 0];
  else if (hp <= 3) rgb1 = [0, c, x];
  else if (hp <= 4) rgb1 = [0, x, c];
  else if (hp <= 5) rgb1 = [x, 0, c];
  else if (hp <= 6) rgb1 = [c, 0, x];
  else throw Error('Unreachable code');

  const m = l - c * 0.5;

  const rgb = rgb1.map((value) => Math.round(255 * (value + m))) as typeof rgb1;
  return rgb;
}

export function isHexColor(hex: string): RegExpExecArray | null {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result;
}

function PadNum(c) {
  const hex = c.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
}

/**
 * get hex from r g b
 *
 * @param rgb
 * @returns hex string
 */
export function rgbToHex([r, g, b]: RGBColor): HexColor {
  return `#${[r, g, b].map((n) => PadNum(n)).join('')}`.toUpperCase();
}

/**
 * Get color group name by parsing name, discard step in the name
 * e.g. Global Warming 6 -> Global Warming
 *
 * @param {Object} colorRange
 * @return {string | null}
 */
export function getColorGroupByName(colorRange: ColorRange): string | null {
  if (!colorRange || typeof colorRange.name !== 'string') {
    return null;
  }

  return colorRange.name.replace(/\b[^a-zA-Z]+$/, '');
}

/**
 * Get a reversed colorRange
 * @param reversed
 * @param colorRange
 */
export function reverseColorRange(reversed: boolean, colorRange: ColorRange): ColorRange | null {
  if (!colorRange) return null;
  // if (colorRange.reversed) return colorRange;
  return {
    ...colorRange,
    reversed,
    colors: colorRange.colors.slice().reverse()
  };
}

/**
 * given a list of rgb arrays it will generate a linear gradient css rule
 * @param direction
 * @param colors
 * @return
 */
export function createLinearGradient(direction: string, colors: RGBColor[]) {
  const step = parseFloat((100.0 / colors.length).toFixed(2));
  const bands = colors.map((rgb, index) => {
    return `rgba(${rgb.join(',')}, 1) ${step * index}%, rgba(${rgb.join(',')}, 1) ${
      step * (index + 1)
    }%`;
  });

  return `linear-gradient(to ${direction}, ${bands.join(',')})`;
}

/**
 * Convert color to RGB
 */
export function colorMaybeToRGB(color: unknown): RGBColor | null {
  if (isRgbColor(color)) {
    return color as RGBColor;
  }

  if (typeof color === 'string') {
    const rgbObj = d3Rgb(color);
    if (Number.isFinite(rgbObj?.r) && Number.isFinite(rgbObj?.g) && Number.isFinite(rgbObj?.b)) {
      return [rgbObj.r, rgbObj.g, rgbObj.b];
    }
  }

  return null;
}

/**
 * Whether color is rgb
 * @returns
 */
export function isRgbColor(color: unknown): boolean {
  return Boolean(
    color &&
      Array.isArray(color) &&
      color.length === 3 &&
      color.every((n) => Number.isFinite(n) && n <= 255 && n >= 0)
  );
}

/**
 * Take color values in 0-255 range and map to [0, 1]
 */
export function normalizeColor(color: number[]): number[] {
  return color.map((component) => component / 255.0);
}
