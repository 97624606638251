// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {DateTime, Interval} from 'luxon';
import {Dispatch} from 'react';

import {parse} from '@loaders.gl/core';
import {ArrowLoader} from '@loaders.gl/arrow';
import {ArrowTable} from '@loaders.gl/schema';

import {PLASTIC_DATA_URL, PLASTIC_TIME_RANGE_URL} from '@kepler.gl/constants';
import {TimeRange} from '@kepler.gl/types';
import {LoadedPlasticDataEntry, setTimebarDomain} from '@kepler.gl/actions';

export async function fetchTimeDomain(dispatch: Dispatch<any>) {
  const timerange = await (
    fetch(PLASTIC_TIME_RANGE_URL).then((data) => data.json()) as Promise<TimeRange>
  ).then((timerange) => {
    const dateFirstDate = DateTime.fromISO(timerange.firstDate).toJSDate();
    const dateLastDate = DateTime.fromISO(timerange.lastDate).toJSDate();
    return [dateFirstDate, dateLastDate] as [Date, Date];
  });

  const action = setTimebarDomain({newDomain: timerange});
  dispatch(action);
}

export async function fetchPlasticData(timeRange: Interval): Promise<LoadedPlasticDataEntry> {
  let dataUrl = new URL(PLASTIC_DATA_URL);

  const minDate = timeRange?.start ?? undefined;
  const maxDate = timeRange?.end ?? undefined;
  if (minDate !== undefined) {
    dataUrl.searchParams.append('minDate', minDate.toISODate());
  }
  if (maxDate !== undefined) {
    dataUrl.searchParams.append('maxDate', maxDate.toISODate());
  }

  const table = (await fetch(dataUrl.href)
    .then((data) => data.arrayBuffer())
    .then((buf) => new Uint8Array(buf))
    .then((buf) =>
      parse(buf, ArrowLoader, {
        arrow: {
          shape: 'arrow-table'
        }
      })
    )) as ArrowTable;

  return {
    table: table,
    chunkTime: timeRange
  };
}
