// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import {combineReducers, applyMiddleware, compose} from 'redux';
import {configureStore} from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import {DeepPartial} from 'tsdef';
import logger from 'redux-logger';

import keplerGlReducer, {enhanceReduxMiddleware, KeplerGlState} from '@kepler.gl/reducers';
import {LayerClasses as DefaultLayerClasses} from '@kepler.gl/layers';
import {STYLE_URL} from '@kepler.gl/constants';
import {StreamingAppType} from '@kepler.gl/schemas';


const LOG_ACTIONS: boolean = false;

const mapStyles = {
  light: {
    id: 'light',
    label: 'Light',
    url: `${STYLE_URL}/basemap-light.json`,
    icon: 'http://tile.openstreetmap.org/0/0/0.png'
  },
  dark: {
    id: 'dark',
    label: 'Dark',
    url: `${STYLE_URL}/basemap-dark.json`,
    icon: 'https://a.basemaps.cartocdn.com/dark_all/0/0/0.png'
  },
  thetaoWMTSLayer: {
    id: 'thetaoWMTSLayer',
    label: 'Sea water potential',
    url: `${STYLE_URL}/thetao.json`,
    icon: 'http://wmts.marine.copernicus.eu/teroWmts/?service=WMTS&version=1.0.0&request=GetTile&tilematrixset=EPSG:3857&style=&tilematrix=0&tilerow=0&tilecol=0&layer=GLOBAL_MULTIYEAR_PHY_001_030/cmems_mod_glo_phy_myint_0.083deg_P1D-m_202311/thetao'
  },
  uoWMTSLayer: {
    id: 'uoWMTSLayer',
    label: 'water eastward velocity',
    url: `${STYLE_URL}/uo.json`,
    icon: 'http://wmts.marine.copernicus.eu/teroWmts/?service=WMTS&version=1.0.0&request=GetTile&tilematrixset=EPSG:3857&style=&tilematrix=0&tilerow=0&tilecol=0&layer=GLOBAL_MULTIYEAR_PHY_001_030/cmems_mod_glo_phy_myint_0.083deg_P1D-m_202311/uo'
  },
  voWMTSLayer: {
    id: 'voWMTSLayer',
    label: 'water northward velocity',
    url: `${STYLE_URL}/vo.json`,
    icon: 'http://wmts.marine.copernicus.eu/teroWmts/?service=WMTS&version=1.0.0&request=GetTile&tilematrixset=EPSG:3857&style=&tilematrix=0&tilerow=0&tilecol=0&layer=GLOBAL_MULTIYEAR_PHY_001_030/cmems_mod_glo_phy_myint_0.083deg_P1D-m_202311/vo'
  },
  mnkcepiWMTSLayer: {
    id: 'mnkcepiWMTSLayer',
    label: 'Micronekton',
    url: `${STYLE_URL}/mnkc_epi.json`,
    icon: 'http://wmts.marine.copernicus.eu/teroWmts/?service=WMTS&version=1.0.0&request=GetTile&tilematrixset=EPSG:3857&style=&tilematrix=0&tilerow=0&tilecol=0&layer=GLOBAL_MULTIYEAR_BGC_001_033/cmems_mod_glo_bgc_my_0.083deg-lmtl_PT1D-i_202211/mnkc_epi'
  },
  nppWMTSLayer: {
    id: 'nppWMTSLayer',
    label: 'Net primary productivity of biomass',
    url: `${STYLE_URL}/npp.json`,
    icon: 'http://wmts.marine.copernicus.eu/teroWmts/?service=WMTS&version=1.0.0&request=GetTile&tilematrixset=EPSG:3857&style=&tilematrix=0&tilerow=0&tilecol=0&layer=GLOBAL_MULTIYEAR_BGC_001_033/cmems_mod_glo_bgc_my_0.083deg-lmtl_PT1D-i_202211/npp'
  }
};

const baseMaps = ['light', 'dark'];

const rasterLayers = [
  'thetaoWMTSLayer',
  'uoWMTSLayer',
  'voWMTSLayer',
  'mnkcepiWMTSLayer',
  'nppWMTSLayer'
];

const LayerClasses = {
  ...DefaultLayerClasses
};

const DEFAULT_START_DATE: Date = new Date('2000-05-15T00:00:00Z');
const defaultKeplerState: DeepPartial<KeplerGlState> = {
  mapStyle: {
    mapStyles,
    baseMaps,
    rasterLayers,
    styleType: 'dark',
    selectedRasters: [''],
    currentDate: DEFAULT_START_DATE
  },
  visState: {
    layerClasses: LayerClasses as any,
    animationConfig: {
      isAnimating: true,
      hideControl: true,
      speed: 2
    }
  },
  mapState: {
    latitude: 20,
    longitude: -80,
    zoom: 4
  },
  uiState: {
    currentModal: undefined,
    timebarCurrentDateMs: DEFAULT_START_DATE.getTime()
  },
  streamingState: {
    appType: 'turtle'
  }
};

const defaultPlasticKeplerState: DeepPartial<KeplerGlState> = {
  ...defaultKeplerState,
  mapStyle: {
    ...defaultKeplerState.mapStyle,
    timeRange: 1
  },
  mapState: {
    latitude: 0,
    longitude: 0, 
    zoom: 2.5 
  },
  streamingState: {
    appType: 'plastic'
  }
};

const plugins = {};

export function initKeplerState(appType: StreamingAppType) {
  let initialState: DeepPartial<KeplerGlState>;
  switch (appType) {
    case 'turtle':
      initialState = defaultKeplerState;
      break;
    case 'plastic':
      initialState = defaultPlasticKeplerState;
      break;
  }
  const customizedKeplerGlReducer = keplerGlReducer.initialState(initialState).plugin(plugins);
  const reducers = combineReducers({
    keplerGl: customizedKeplerGlReducer
  });

  const rawMiddlewares = [thunk];
  if (LOG_ACTIONS) {
    // @ts-ignore
    rawMiddlewares.push(logger);
  }
  const middlewares = enhanceReduxMiddleware(rawMiddlewares);
  const enhancers = [applyMiddleware(...middlewares)];

  return configureStore({
    reducer: reducers,
    enhancers: enhancers,
    middleware: middlewares
  });
}
