// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import { loadingAdditionalConfigsEnded } from "@kepler.gl/actions";
import { ADDITIONAL_CONFIG_URL } from "@kepler.gl/constants";
import { Dispatch } from "react";
import { AdditionalConfig } from '@kepler.gl/types';

export async function fetchAdditionalDataConfigs(dispatch: Dispatch<any>) {
    const availableConfigs: AdditionalConfig[] = await fetch(ADDITIONAL_CONFIG_URL)
      .then((res) => res.json())
      .then((configs) => configs.map((config: any) => ({...config, type: 'additional-config'})));
  
    dispatch(loadingAdditionalConfigsEnded({additionalConfigs: availableConfigs}));
  }
  