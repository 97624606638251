// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import config from './config.json';

// server url : http://mercator.geomatys.com/api
// local url :  http://localhost:3000

export const SERVER_URL: string = config.SERVER_URL;

export const TURTLE_DATA_URL: string = SERVER_URL + '/turtles/data';
export const TURTLE_CONFIG_URL: string = SERVER_URL + '/turtles/list_config';
export const TURTLE_NESTING_AREA_URL: string = SERVER_URL + '/turtles/nesting_area';
export const TURTLE_TIME_RANGE: string = SERVER_URL + '/turtles/time_range';

export const PLASTIC_TIME_RANGE_URL: string = SERVER_URL + '/plastics/timerange';
export const PLASTIC_DATA_URL: string = SERVER_URL + '/plastics/data';

export const ADDITIONAL_CONFIG_URL: string = SERVER_URL + '/other_datas/all';
export const ADDITIONAL_DATA_URL: string = SERVER_URL + '/other_datas/data';

export const STYLE_URL: string = SERVER_URL + '/styles/get';

export const ASSETS_ROUTE = SERVER_URL + '/file/';
export const NESTING_ICON_URL: string = ASSETS_ROUTE + 'nesting-icon.png';

export const NB_TURTLE_RANGE: [number, number] = [1, 1000];
export const DEFAULT_NB_TURTLES: number = 100;
export const DAYS_TO_PREFETCH: number = 3;

export const PLAYER_TICKRATE_MS: number = 1000;
