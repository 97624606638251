// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import KeplerTable from '@kepler.gl/table';
import {DataContainerInterface} from '@kepler.gl/utils';
import Layer from './base-layer';

export class EmptyLayer extends Layer {
  calculateDataAttribute(_keplerTable: KeplerTable, _getPosition: any) {
    return [];
  }

  getPositionAccessor(_dataContainer?: DataContainerInterface): (...args: any[]) => any {
    return () => null;
  }

  updateLayerMeta(_dataContainer: DataContainerInterface, _getPosition: any): void {
    return;
  }
}

export default EmptyLayer;
