// SPDX-License-Identifier: MIT
// Copyright contributors to the kepler.gl project

import React from 'react';
import styled from 'styled-components';
import {KEPLER_GL_NAME, KEPLER_GL_VERSION, KEPLER_GL_WEBSITE} from '@kepler.gl/constants';

const LogoTitle = styled.div`
  display: inline-block;
  margin-left: 6px;
`;

const LogoName = styled.div`
  .logo__link {
    color: ${props => props.theme.logoColor};
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1.17px;
  }
`;
const LogoVersion = styled.div`
  font-size: 10px;
  color: ${props => props.theme.subtextColor};
  letter-spacing: 0.83px;
  line-height: 14px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const LogoSvgWrapper = styled.div`
  margin-top: 0px;
`;

const TurtleLogoSvg = () => (
  <svg
    fill="#1FBAD6"
    height="35px"
    width="20px"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 338.199 338.199"
  >
    <g id="XMLID_311_">
      <g>
        <g>
          <path
            d="M127.088,141.809l-36.345-50.03c-10.148,8.759-18.831,19.169-25.586,30.845H60c-33.138,0-60,26.863-60,60h49.1
				c0,9.446,1.098,18.634,3.161,27.451l58.864-19.127L127.088,141.809z"
          />
          <path
            d="M194.916,130.062v-0.001l36.354-50.055c-6.963-4.227-14.374-7.788-22.17-10.544V42.624c0-22.091-17.908-40-40-40
				c-22.092,0-40,17.909-40,40v26.838c-7.797,2.756-15.209,6.318-22.172,10.545l36.354,50.054H194.916z"
          />
          <path
            d="M58.44,229.109c4.555,10.831,10.649,20.854,18.017,29.792c-14.349,25.382-8.402,58.125,15.238,76.673l32.629-41.589
				c10.891,4.383,22.571,7.21,34.769,8.219l0.004-61.827l-41.823-30.392L58.44,229.109z"
          />
          <path
            d="M278.199,122.624h-5.157c-6.755-11.676-15.439-22.086-25.587-30.846l-36.344,50.029l15.963,49.14l58.864,19.126
				c2.063-8.816,3.161-18.004,3.161-27.449h49.1C338.199,149.487,311.337,122.624,278.199,122.624z"
          />
          <path
            d="M220.926,209.984l-41.822,30.391l0.005,61.828c12.196-1.009,23.877-3.836,34.767-8.219l32.629,41.589
				c23.641-18.548,29.588-51.29,15.238-76.673c7.367-8.937,13.462-18.961,18.017-29.791L220.926,209.984z"
          />
          <polygon points="192.764,150.06 145.436,150.06 130.807,195.096 169.1,222.922 207.393,195.096 			" />
        </g>
      </g>
    </g>
  </svg>
);

//TODO - Make this better later to switch using apptype in streaming state but don't have the time rn
// i can't figure out how to pass properly the streaming state of map control props to this one 
const PlasticBottleLogoSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    width="30px" 
    height="45px"
  >
    <path style={{fill:"#E4EAF8"}} d="M341.423,126.564l-55.45-62.381c-2.783-3.131-4.319-7.173-4.319-11.362V33.67h-51.307v19.151
      c0,4.188-1.537,8.232-4.319,11.362l-55.451,62.382c-5.565,6.261-8.639,14.347-8.639,22.724h188.125
      C350.063,140.911,346.988,132.825,341.423,126.564z"/>
    <path style={{fill:"#D5DCED"}} d="M289.254,67.875l-3.281-3.692c-1.285-1.446-2.271-3.101-2.996-4.859l0,0
      c-0.416-1.006-0.735-2.049-0.96-3.116c-0.004-0.024-0.015-0.045-0.019-0.068c-0.224-1.085-0.344-2.194-0.344-3.319V42.221h-51.307
      v10.601c0,4.188-1.537,8.23-4.319,11.361l-55.451,62.382c-5.565,6.261-8.639,14.347-8.639,22.724h68.409
      c0-8.377,1.118-16.463,3.141-22.724l15.696-47.004c2.33-6.979,8.863-11.685,16.222-11.685h23.849V67.875z"/>
    <path style={{fill:"#9BF57D"}} d="M281.653,42.221h-51.307c-4.722,0-8.551-3.829-8.551-8.551V16.568c0-4.722,3.829-8.551,8.551-8.551
      h51.307c4.722,0,8.551,3.829,8.551,8.551V33.67C290.205,38.393,286.376,42.221,281.653,42.221z"/>
    <path style={{fill:"#8CE16E"}} d="M247.449,33.67V16.568c0-4.722,3.829-8.551,8.551-8.551h-25.653c-4.722,0-8.551,3.829-8.551,8.551
      V33.67c0,4.722,3.829,8.551,8.551,8.551H256C251.278,42.221,247.449,38.393,247.449,33.67z"/>
    <path style={{fill:"#BBE8FC"}} d="M350.063,196.142v-51.307H161.937v51.307c0,9.445,7.658,17.102,17.102,17.102
      c-9.445,0-17.102,7.656-17.102,17.102v8.551c0,9.445,7.658,17.102,17.102,17.102c-9.445,0-17.102,7.656-17.102,17.102v8.551
      c0,9.445,7.658,17.102,17.102,17.102c-9.445,0-17.102,7.656-17.102,17.102v8.551c0,9.445,7.658,17.102,17.102,17.102
      c-9.445,0-17.102,7.656-17.102,17.102v8.551c0,9.445,7.658,17.102,17.102,17.102c-9.445,0-17.102,7.656-17.102,17.102v8.551
      c0,9.445,7.658,17.102,17.102,17.102c-9.445,0-17.102,7.656-17.102,17.102v25.653c0,18.891,15.313,34.205,34.205,34.205h119.716
      c18.892,0,34.205-15.314,34.205-34.205v-25.653c0-9.446-7.658-17.102-17.102-17.102c9.445,0,17.102-7.658,17.102-17.102v-8.551
      c0-9.446-7.658-17.102-17.102-17.102c9.445,0,17.102-7.658,17.102-17.102v-8.551c0-9.446-7.658-17.102-17.102-17.102
      c9.445,0,17.102-7.658,17.102-17.102v-8.551c0-9.446-7.658-17.102-17.102-17.102c9.445,0,17.102-7.658,17.102-17.102v-8.551
      c0-9.446-7.658-17.102-17.102-17.102c9.445,0,17.102-7.658,17.102-17.102v-8.551c0-9.446-7.658-17.102-17.102-17.102
      C342.405,213.244,350.063,205.587,350.063,196.142z"/>
  </svg>
);


interface KeplerGlLogoProps {
  appName?: string;
  version?: string | boolean;
  appWebsite?: string;
}

const KeplerGlLogo = ({
  appName = KEPLER_GL_NAME,
  appWebsite = KEPLER_GL_WEBSITE,
  version = KEPLER_GL_VERSION,
}: KeplerGlLogoProps) => (
  <LogoWrapper className="side-panel-logo">
    <LogoSvgWrapper>
      {appName === 'Simulated Plastic Drift' ? <PlasticBottleLogoSvg /> : <TurtleLogoSvg />}
    </LogoSvgWrapper>
    <LogoTitle className="logo__title">
      <LogoName className="logo__name">
        <a className="logo__link" target="_blank" rel="noopener noreferrer" href={appWebsite}>
          {appName}
        </a>
      </LogoName>
      {version ? <LogoVersion className="logo__version">{version}</LogoVersion> : null}
    </LogoTitle>
  </LogoWrapper>
);

export default KeplerGlLogo;
